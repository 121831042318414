$kiu_light: #1492a7;
$success: #198754;
$danger: #dc3545;

.clickable {
  cursor: pointer;
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

.medium-border {
  border-width: medium;
}

.responsive-video {
  width: 67%;
}

@media only screen and (max-width: 600px) {
  .responsive-video {
    width: 100%;
  }
}

.trix-content {
  mark {
    background-color: unset;
    font-size: 1.25rem;
  }

  > div > img {
    margin: auto;
    display: block;
  }
}

.audio-player {
  padding: 5px;
  border: 2px solid $kiu-light;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  .audio-controls {
    margin-top: 1rem;
    display: flex;
    align-content: center;
    justify-content: space-between;

    i {
      margin-right: 0.5rem;
    }

    .audio-timeline {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.service-form-buttons {
  .btn {
    width: 8rem;

    &.no-hover:hover {
      background-color: initial;

      &.btn-outline-success {
        color: $success;
      }

      &.btn-outline-danger {
        color: $danger;
      }
    }
  }
}
