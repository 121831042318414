.service-form-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .modal-path-no-button {
    color: #dc3545 !important;
    background-color: transparent !important;
    border: #dc3545 2px solid;

    &:hover {
      color: white !important;
      background-color: #dc3545 !important;
    }
  }

  .modal-path-no-button.active {
    background-color: #dc3545 !important;
    color: white !important;
    pointer-events: none;
  }

  .modal-path-yes-button {
    color: #28a745 !important;
    background-color: transparent !important;
    border: #28a745 2px solid;

    &:hover {
      color: white !important;
      background-color: #28a745 !important;
    }
  }

  .modal-path-accepted-button {
    background-color: #28a745 !important;
    pointer-events: none;
  }

  .modal-path-refused-button {
    color: #dc3545 !important;
    background-color: transparent !important;
    border: #dc3545 2px solid;
    pointer-events: none;
  }
}
