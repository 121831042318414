#sti_locator_form {
  label.required:after {
    content: " *";
    color: red;
  }
}

#results_table {
  width: 75%;

  @media only screen and (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
    width: 85%;
  }

  .result {
    position: relative;
    margin-bottom: 1em;

    .favorite {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.locations {
  display: inline-block;
  margin-left: 5%;

  @media only screen and (max-width: 768px) {
    margin-left: unset;
  }
}
