$kiu_light: #1492a7;
$kiu_dark: #0f2a66;
$kiu_extra_light: #45c3d8;

.kiu-light {
  background-color: $kiu_light;
}

.kiu-dark {
  background-color: $kiu_dark;
}

.kiu-extra-light {
  background-color: $kiu_extra_light;
}

.text-orange {
  color: orange;
}
