.episode-list-page {
  form {
    .episode-list-searchbar {
      flex-grow: 1;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .episode-list-buttons {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }

    a {
      background-color: gray !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
