@use "../dimensions";

.cookie-modal {
  position: fixed;
  bottom: 1vh;
  left: 1vw;
  right: 1vw;
  border-radius: 0.25rem;
  background-color: rgb(128, 128, 128);
}

.cookie-modal-body {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.dtc-screen-bottom-element {
  margin-bottom: 17vw;
}

.landing-body {
  max-width: 992px;
}

.landing-table img {
  width: 20%;
}

@media screen and (max-width: 992px) {
  .landing-body {
    margin: 0;
  }
}

@media (min-width: dimensions.$desktop-width-min) {
  .landing-table {
    display: none !important;
  }
}

.landing-div {
  display: none;
}

@media (min-width: dimensions.$desktop-width-min) {
  .landing-div {
    display: flex;
  }
}

.landing-header-1 {
  width: 20%;
  padding-right: 5%;
}

.landing-header-2 {
  width: 20%;
  padding-left: 5%;
  padding-right: 5%;
}

.landing-header-3 {
  padding-left: 5%;
  width: 20%;
}

.landing-header-4 {
  padding-left: 5%;
  width: 20%;
}
