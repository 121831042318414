.true-false-description {
  font-size: 1.25rem;
}

.card-deck.true-false-question {
  .card {
    flex-basis: 0%;

    i {
      width: 16px;
    }
  }
}
