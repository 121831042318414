.launch-button {
  font-size: 1.25rem;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .launch-button {
    font-size: 1.25rem;
    font-weight: bold;
    width: 100%;
  }
}
