.iat#progressbar li {
  width: 14.28%;
}

.iat-multistep {
  .form-step {
    background-color: white;
    position: relative;

    .iat-centered-p {
      font-weight: 700;
      text-align: center;
      margin-bottom: 3rem;
    }

    .iat-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      display: block;
      border-radius: 0.3rem;
      border: 1px solid #343a40;
      max-width: 100%;
      height: auto;
    }
  }

  .form-step:not(:first-of-type) {
    display: none;
  }

  .mdc-select__anchor.mdc-ripple-upgraded {
    margin-bottom: 1.5rem;
  }

  &.mdc-select--disabled {
    .mdc-select__selected-text {
      color: green;
    }
  }
}

.bottom-nav {
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.modal {
  .no-answer-button {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
}
