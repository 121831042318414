.bottom-nav {
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;

  a:hover {
    text-decoration: none;
  }
}

.participant-nav-buttons {
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
