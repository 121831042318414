.multiple-choice-page,
.true-false-page {
  .multiple-choice-question,
  .true-false-question {
    display: flex;
    justify-content: space-between;

    .mdc-card {
      margin-top: 1rem;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 49%;
      height: 3.5rem;
      border: 4px solid blue;

      p {
        margin-bottom: 0;
        font-weight: bold;
        color: blue;

        i {
          position: absolute;
          left: 1rem;
          top: 50%;
        }
      }
    }

    .mdc-card.mc-correct {
      border-color: #28a745;

      p {
        color: #28a745;
      }
    }

    .mdc-card.mc-incorrect {
      border-color: #dc3545;

      p {
        color: #dc3545;
      }
    }
  }

  .alert-correct {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    margin-top: 1.5rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alert-incorrect {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-top: 1.5rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
