.participant-contact-page {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .mdc-button {
    width: 100%;
  }
}
