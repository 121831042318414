.login-image {
  margin-left: auto;
  margin-right: auto;
  max-width: 256px;
  display: block;
}

.login-content {
  display: flex;
  justify-content: center;

  .login-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .quick-signin {
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    .quick-signin-body {
      min-height: 1px;
      padding: 1.25rem;
    }
  }

  form {
    width: 100%;
    text-align: center;

    .login-password-container {
      display: flex;

      .login-password {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .password-toggle-icon {
        margin-left: 8px;
        margin-top: 24px;
        padding-left: 18px;
        padding-top: 17px;
        width: 56px;
        height: 56px;
        border: 1px solid rgba(0, 0, 0, 0.38);
        border-radius: 5px;

        i {
          padding-right: 15px;
        }
      }
    }

    button {
      width: 100%;
    }
  }

  .login-list {
    text-align: center;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding-left: 0;
    margin-bottom: 1.5rem;

    .login-list-item {
      color: #006ffa;
      position: relative;
      display: block;
      padding: 0.75rem 1.25rem;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-width: 0 0 1px;
    }

    .login-list-item:last-of-type {
      border-bottom: none;
    }
  }
}
