$start-color: #0e2a66;
$tools-color: #0177af;
$favorites-color: #d3055c;
$resources-color: #187f05;
$contact-color: #ec681f;

#homes-show {
  height: calc(100vh - 88px);
  max-height: 45rem;

  h1 {
    font-size: 5vh;
  }
  h3 {
    font-size: 4vh;
  }
  p {
    font-size: 3vh;
  }

  .text-info {
    a {
      font-size: 3vh;
    }
  }

  #other-buttons-block {
    flex-grow: 2;

    a {
      flex: 0 0 50%;
    }
  }

  .home-images {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  #start-btn {
    &:hover {
      text-decoration-color: $start-color;
    }
    div {
      @extend .home-images;
      background-image: url("../../images/participants/start.png");
    }
    h3 {
      color: $start-color;
    }
  }

  #tools-btn {
    &:hover {
      text-decoration-color: $tools-color;
    }
    div {
      @extend .home-images;
      background-image: url("../../images/participants/tools.png");
    }
  }

  #favorites-btn {
    &:hover {
      text-decoration-color: $favorites-color;
    }
    div {
      @extend .home-images;
      background-image: url("../../images/participants/favorites.png");
    }
  }

  #resources-btn {
    &:hover {
      text-decoration-color: $resources-color;
    }
    div {
      @extend .home-images;
      background-image: url("../../images/participants/resources.png");
    }
  }

  #contact-btn {
    &:hover {
      text-decoration-color: $contact-color;
    }
    div {
      @extend .home-images;
      background-image: url("../../images/participants/contact_us.png");
    }
  }
}

.tools-color {
  color: $tools-color;
}

.favorites-color {
  color: $favorites-color;
}

.resources-color {
  color: $resources-color !important;
}

.contact-color {
  color: $contact-color;
}

.tools-item {
  border: 1px solid $tools-color;
  border-radius: 5px;
  &:hover {
    color: $tools-color;
  }
}

.contact-border {
  border: 2px solid $contact-color;
  border-radius: 5px;
}

.contact-image {
  max-width: 300px;
  max-height: 200px;
}

@media only screen and (max-width: 600px) {
  .contact-image {
    max-width: 200px;
    max-height: 200px;
  }
}

.resource-button {
  &:hover {
    background-color: $resources-color;
  }
}

#resources-list {
  margin: auto;
  .btn.resources-color {
    font-size: unset;
  }
  > a:hover {
    color: $resources-color;
  }
  * > a.mdc-button:hover {
    background-color: $resources-color;
  }
  * > a:not(.mdc-button):hover {
    color: $resources-color;
  }
  * > h4 > .btn {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  #homes-show {
    h3 {
      font-size: 3.5vh;
    }
  }
  #resources-list {
    .card-header > h4 {
      text-align: center;
    }
  }
  .contact-image {
    max-width: 100%;
  }
}

.tool-icon {
  max-height: 60px;
}

.baseline-btn {
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.homepage-tool-list {
  .tool-list-link {
    &:hover {
      text-decoration: none;
    }

    display: flex;
    align-items: center;
  }

  .tool-list-link-secondary {
    color: #6c757d;
    background-color: #d6d8db;
    pointer-events: none;
  }

  .mdc-list-item {
    width: 100%;
    height: 4rem;
  }

  .mdc-list-item__primary-text {
    img {
      max-height: 50px;
      margin-right: 1rem;
    }
  }
}
