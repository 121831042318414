.favorites-search-form {
  display: flex;
  align-items: center;

  .mdc-text-field--outlined {
    flex-grow: 1;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .favorites-search-reset-button {
    background-color: gray;
  }
}

.favorites-lists {
  .mdc-card {
    padding: 1.25rem;
    margin-bottom: 1rem;
  }

  .favorites-list-link {
    &:hover {
      text-decoration: none;
    }

    .mdc-list-item__primary-text {
      color: #1492a7;
    }
  }
}
