@import "../kiu-theme.scss";

.kiu-light-outline {
  border: 3px solid;
  border-radius: 10px;
  color: $kiu_light;
  border-color: $kiu_light;
}

.feedback-container {
  margin-bottom: 1.5rem;
}

.star-container {
  .button-group {
    display: inline-block;
  }

  .mdc-form-field {
    label {
      display: none;
    }
  }
}

.rating-star:not(:disabled):not(.disabled):active,
.rating-star:not(:disabled):not(.disabled).active,
.show > .rating-star.dropdown-toggle,
.rating-star:hover {
  .far {
    display: none;
  }

  .fas {
    color: $kiu_light;
    display: inline-block;
  }
}

.rating-star {
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  margin-bottom: 0;
  cursor: pointer;

  &:focus,
  &.focus {
    box-shadow: none;
  }

  .far {
    color: black;
  }

  .fas {
    display: none;
  }

  input {
    display: none;
  }
}

.star-hover {
  .far {
    display: none;
  }
  .fas {
    color: $kiu_light;
    display: inline-block;
  }
}

.rating-star {
  font-size: 2rem !important;
}

@media only screen and (max-width: 600px) {
  .rating-star {
    width: 58px;
    font-size: 1.45rem !important;
    padding-left: unset;
    padding-right: unset;
  }
}

@media only screen and (max-width: 370px) {
  .rating-star {
    width: 45px;
    font-size: 0.75rem !important;
    padding-left: unset;
    padding-right: unset;
  }
}
