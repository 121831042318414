.app-module {
  margin-bottom: 1rem;

  .mdc-card {
    padding: 1.25rem;

    .mdc-list {
      .app-module-list-link {
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: none;
        }

        .mdc-list-item {
          width: 100%;

          .fas {
            margin-right: 0.5rem;
          }

          .app-module-tool {
            margin-right: 0.5rem;
          }
        }
      }

      .app-module-list-link-secondary {
        color: #6c757d;
        background-color: #d6d8db;
      }
    }
  }
}
