#instructions {
  text-align: center;
  font-size: 1em;
}

#locator_container {
  display: flex;
  align-items: center;

  #question_list {
    display: inline-block;
    border-radius: 8px;
    width: 70%;
    border: 1px solid gray;
    text-align: left;
    margin-right: 1rem;

    .checkbox-list-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      label {
        margin-bottom: 0;
      }
    }
  }

  #place_list {
    display: inline-block;
    text-align: center;

    div {
      padding: 5px;
      margin: 5px;
      background-color: #a6ecb2;
      border-radius: 8px;
    }

    .red {
      background-color: #ff9d98;
    }

    .yellow {
      background-color: #ffe680;
    }

    .green {
      background-color: #a6ecb2;
    }
  }
}
