@import "../hiv_risk_calculator/stylesheets/base/_variables.scss";
@import "../hiv_risk_calculator/stylesheets/grid.scss";

#sti-risk-calculator-tool {
  #calculator {
    border: $thick-outline;
    border-radius: $rounded-square-radius;
    width: 100%;

    button {
      border: $thin-outline;
      background-color: $secondary-background-color;
      border-radius: $rounded-square-radius;
      padding: $square-padding;

      &.active {
        background-color: $action-color;
      }
    }

    img,
    picture {
      margin: 0;
      max-width: 100%;
    }

    #column_holder {
      display: flex;
      border: 1px solid #0f2a65;
      border-radius: 3px;
      justify-content: space-around;
      word-break: break-word;
      .key-header {
        font-size: 1.5em;
      }
      .key-text {
        font-size: 1.25em;
      }
      @media only screen and (max-width: 768px) {
        .key-text {
          font-size: 0.9em;
        }
      }
      div {
        flex: 1;
        span {
          font-size: 1.5em;
        }

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1em;
          @media only screen and (max-width: 768px) {
            font-size: 0.75em;
            margin-left: 10%;
            margin-right: 10%;
          }
          margin-left: 15%;
          margin-right: 15%;
          margin-bottom: 5px;
          margin-top: 5px;

          img {
            width: 20%;
            align-self: center;
            @media only screen and (max-width: 768px) {
              width: 30%;
            }
            @media only screen and (max-width: 480px) {
              width: 25%;
            }
          }
        }
      }
    }
  }

  #factor-group {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(100px, 1fr) 5fr;

    #label {
      border-right: $thin-outline;
      h3 {
        text-transform: capitalize;
        margin: $square-padding;
      }
    }

    #factors {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: $square-padding;
    }
  }

  .bodypart-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: $square-padding;
  }

  .keypad {
    border-bottom: $medium-outline;
    align-items: center;
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 5fr;
  }

  .sex-group {
    border-right: $thin-outline;
    padding: $square-padding;

    h3 {
      text-transform: capitalize;
      margin: $square-padding;
      text-align: center;

      small {
        margin: auto;
      }
    }

    .sexes {
      max-width: 8em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $square-padding;
      gap: 5px;
    }
  }

  #risk {
    border-top: $medium-thick-outline;
    padding: $square-padding;
    padding-top: $square-padding * 2;
    text-align: center;

    h3 {
      margin-bottom: $square-padding;
    }
    h4 {
      margin-bottom: auto;
    }
    p {
      margin-bottom: auto;
    }

    #riskBar {
      height: $base-font-size * 3;
      border-radius: $base-border-radius;
      display: flex;
      z-index: 1;
      border: $medium-outline;
      position: relative;

      .sliders {
        width: 0.5em;
        height: 125%;
        position: absolute;
        left: 0;
        top: calc(-12.5%);
        transition: 1s;
        border-radius: $base-border-radius;
        opacity: 0;
      }

      .bars {
        height: 100%;
        z-index: -1;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: white;
        transition: 1s;
      }

      .arrow {
        opacity: 0;
        border-top: 0.25em solid white;
        border-bottom: 0.25em solid white;
        top: calc(50% - 0.25em);
        position: absolute;
        transition: 1s;

        &.left:after {
          content: "";
          border-top: 1em solid transparent;
          border-right: 1em solid white;
          border-bottom: 1em solid transparent;
          position: absolute;
          top: -1em;
          left: -1em;
        }
        &.right:after {
          content: "";
          border-top: 1em solid transparent;
          border-left: 1em solid white;
          border-bottom: 1em solid transparent;
          position: absolute;
          top: -1em;
          right: -1em;
        }
      }
    }
  }

  #regular {
    font-size: 1.25em;
    width: 100%;
    justify-content: space-around;
    background: linear-gradient(to right, #4cd964, #ffcc00, #ff3b30);

    &.fullbar {
      font-size: 1em;
      width: 35%;
    }
  }

  #gradient {
    font-size: 0;
    width: 0;
    justify-content: center;
    background: linear-gradient(to right, #960800, #4b0400);

    &.fullbar {
      font-size: 1.25em;
      width: 65%;
    }
  }
}
