@import "../../kiu-theme.scss";

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0;
  /*CSS counters to number the steps*/
  counter-reset: step;

  li {
    list-style-type: none;
    text-align: center;
    font-size: 0.75rem;
    width: 33.33%;
    float: left;
    position: relative;
    letter-spacing: 1px;

    &:before {
      content: counter(step);
      counter-increment: step;
      width: 24px;
      height: 24px;
      line-height: 24px;
      display: block;
      color: #333;
      background: lightGrey;
      border-radius: 25px;
      margin: 0 auto 10px auto;
    }

    /*progressbar connectors*/
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: lightGrey;
      position: absolute;
      left: -50%;
      top: 11px;
      z-index: -1; /*put it behind the numbers*/
    }

    &:first-child:after {
      /*connector not needed before the first step*/
      content: none;
    }

    &.active {
      color: $kiu_light;
    }

    &.activated {
      /*marking active/completed steps*/
      /*The number of the step and the connector before it*/
      &:before,
      &:after {
        background: $kiu_light;
        color: white;
      }
    }
  }
}

.multistep {
  .form-step {
    background-color: white;
    position: relative;
  }

  .form-step:not(:first-of-type) {
    display: none;
  }

  .input-step {
    label {
      margin-top: initial;
    }
  }
}
