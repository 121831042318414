#resources-list,
#health-info,
#admin-resources {
  .mdc-card {
    margin-bottom: 1rem;

    .mdc-card-header {
      &:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
      }

      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

    .mdc-card-body {
      min-height: 1px;
      padding: 1.25rem;
      padding-bottom: 0.5rem;

      .mdc-button {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
  }
}
