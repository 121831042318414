.prep-locator,
.sti-locator {
  form {
    .locator-inputs {
      display: flex;
      justify-content: space-between;

      label {
        width: 48%;
      }
    }

    .mdc-form-field {
      align-items: center;

      label {
        margin-bottom: 0;
      }
    }

    button {
      width: 100%;
    }
  }
}
