.baseline-kiu-video {
  &::before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 0.25rem;

  .embedded-kiu-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
