.favorite-button {
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
