.search-form {
  .form-check-input.is-valid ~ .form-check-label {
    color: inherit;
  }

  .form-control.is-valid {
    border-color: inherit;
    padding-right: inherit;
    background-image: none;
  }
}
