@import "./kiu-theme.scss";

.bar {
  background-color: $kiu_dark;
}

.link-blue {
  color: #006ffa;
}

.alert {
  text-align: center;
}

.dtc-logo {
  max-height: 50px;
}

.nav-back {
  bottom: -2px;
  left: 20px;
  border-color: unset;
  color: white;
}

.nav-next {
  bottom: -2px;
  right: 20px;
  border-color: unset;
  color: white;
}

.no-transition {
  transition: all 0.01s ease-out 0s;
}
