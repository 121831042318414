.btn-group-toggle .btn {
  > .fa-check,
  > .fa-times {
    visibility: hidden;
  }
}

.btn-group-toggle .btn.active {
  > .fa-check,
  > .fa-times {
    visibility: visible;
  }
}
